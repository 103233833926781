<template>
  <v-app id="inspire" style="background: #f8f9fb">
    <v-main class="image">
      <v-container class="fill-height">
        <v-row align="center" justify="center">
          <v-col cols="12" sm="12" md="6">
            <app-header />
            <v-card class="elevation-12 rounded-lg tw-py-5">
              <div
                class="tw-rounded-2xl tw-my-5 tw-mx-3 text-h6 font-weight-medium tw-py-3"
                dark
                flat
              >
                <h2
                  :style="{ color: '#0C4DA2' }"
                  class="title-font tw-w-full tw-flex tw-justify-center"
                >
                  Confirm OTP
                </h2>
              </div>

              <p class="text-subtitle-1 tw-p-5 tw-text-center">
                Enter the OTP sent to {{ maskedEmail }}
              </p>
              <otp-input v-model="otp" @finish="otpFinished" />
              <v-card-text>
                <v-form @submit.prevent="confirmOTP" v-model="valid" ref="form">
                  <div class="d-flex justify-end mb-4">
                    <v-btn
                      :disabled="cooldown > 0"
                      variant="text"
                      class="mt-2 px-3"
                      @click="handleResendOTP"
                      color="secondary"
                    >
                      {{
                        cooldown > 0
                          ? `Resend OTP in ${cooldown}s`
                          : 'Resend OTP'
                      }}
                    </v-btn>
                  </div>
                </v-form>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions class="pa-4">
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  size="large"
                  type="submit"
                  :loading="loading"
                  :disabled="!valid || otp.length !== 6"
                  block
                  @click="confirmOTP"
                >
                  Confirm OTP
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="3000">
      {{ snackbarText }}
    </v-snackbar>
    <app-bottom />
  </v-app>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import axios from 'axios'

export default {
  components: {
    appHeader: () => import('@/components/Logo'),
    appBottom: () => import('@/components/BottomImage'),
    otpInput: () => import('@/components/auth/otp-input'),
  },
  name: 'OTPConfirmation',
  data() {
    return {
      valid: false,
      otp: '',
      cooldown: 0,
      snackbar: false,
      snackbarText: '',
      snackbarColor: 'success',
    }
  },
  computed: {
    ...mapState({
      loading: (state) => state.loading,
      email: (state) => state.auth.userEmail,
    }),
    ...mapGetters('auth', ['hasAuthenticationStatus', 'authenticationStatus']),
    maskedEmail() {
      if (!this.email) return ''
      const [name, domain] = this.email.split('@')
      return `${name[0]}${'*'.repeat(name.length - 2)}${
        name[name.length - 1]
      }@${domain}`
    },
  },
  methods: {
    ...mapActions('auth', ['validateLoginOTP']),
    otpFinished() {
      this.valid = true
    },
    async confirmOTP() {
      if (!this.valid || this.otp.length !== 6) return

      this.$store.commit('SET_LOADING', true)

      try {
        await this.validateLoginOTP({ email: this.email, otp: this.otp })
        if (this.hasAuthenticationStatus) {
          if (this.authenticationStatus.variant === 'error') {
            this.showSnackbar(this.authenticationStatus.message, 'error')
          } else {
            this.$router.push({ name: 'Dashboard' })
          }
        }
      } catch (error) {
        this.showSnackbar('Error confirming OTP', 'error')
      } finally {
        this.$store.commit('SET_LOADING', false)
      }
    },
    async handleResendOTP() {
      if (this.cooldown > 0) return

      try {
        const response = await axios.post(`/otp/generate`, {
          appCode: '',
          emailAddress: this.email,
          mobileNumber: '',
          otp: '',
        })

        if (response.data.data === 'succeeded') {
          this.showSnackbar('OTP resent successfully', 'success')
          this.startCooldown()
        } else {
          throw new Error('Failed to resend OTP')
        }
      } catch (error) {
        this.showSnackbar('Error resending OTP', 'error')
      }
    },
    startCooldown() {
      this.cooldown = 30
      const timer = setInterval(() => {
        this.cooldown--
        if (this.cooldown <= 0) {
          clearInterval(timer)
        }
      }, 1000)
    },
    showSnackbar(text, color = 'success') {
      this.snackbarText = text
      this.snackbarColor = color
      this.snackbar = true
    },
  },
  mounted() {
    if (!this.email) {
      this.$router.push({ name: 'Login' })
    }
  },
}
</script>
